<template>
  <div ref="viewport" class="viewport">
    <div ref="viewportTop" class="viewport-top">
      <div ref="logo" class="logo"></div>
      <div ref="service" class="service font-medium">
        <span class="service-and-mrs">S</span
        ><span class="service-body"
          ><span ref="innerService" class="innerService">ervice</span></span
        >
      </div>
      <div ref="mrs" class="mrs font-medium">
        <div class="mrs-inner">
          <div ref="mrInner" class="mr-inner">MR</div>
        </div>
        <div class="mrs-inner">
          <div ref="innerAi" class="mr-ai-inner">.ai</div>
        </div>
      </div>
    </div>
    <a
      ref="icp"
      class="icp"
      href="http://www.beian.miit.gov.cn/"
      target="_blank"
    >
      沪ICP备 17042570号-1
    </a>
  </div>
</template>

<script>
import FAnimation, { Animation } from "@/utils/FAnimation";
export default {
  name: "Viewport",
  props: {
    home: {
      type: [Object, HTMLDivElement],
      default: null
    }
  },
  data() {
    return {
      fa: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.viewport.style.height = window.innerHeight + "px";
    });
    window.addEventListener("resize", this.onResize, false);
    setTimeout(() => {
      this.fa = new FAnimation(this.home);
      this.fa.setFrameHeight(this.$root.windowHeight / 2);
      this.fa.clearAnimation();
      this.fa.createElAnimations(this.$refs.logo, [
        new Animation(FAnimation.animationStore.fadeIn, {}, [1, 2])
      ]);
      // 除以16是转换rem
      this.fa.createElAnimations(this.$refs.viewportTop, [
        new Animation(FAnimation.animationStore.moveUp, { y: 35 / 16 }, [
          11,
          12
        ]),
        new Animation(
          FAnimation.animationStore.moveUp,
          { y: 35 / 16, originY: 35 / 16 },
          [13, 14]
        ),
        new Animation(
          FAnimation.animationStore.moveUp,
          { y: 35 / 16, originY: 70 / 16 },
          [15, 16]
        ),
        new Animation(
          FAnimation.animationStore.moveDown,
          { y: 105 / 16, originY: -105 / 16 },
          [17, 18]
        )
      ]);
      this.fa.createElAnimations(this.$refs.service, [
        new Animation(FAnimation.animationStore.moveDown, { y: 57 / 16 }, [
          8,
          9
        ]),
        new Animation(
          FAnimation.animationStore.moveUp,
          { y: 57 / 16, originY: -57 / 16 },
          [20, 21]
        ),
        new Animation(FAnimation.animationStore.moveRight, { x: 60 / 16 }, [
          21,
          22
        ]),
        new Animation(
          FAnimation.animationStore.moveRight,
          { x: 14 / 16, originX: 60 / 16 },
          [32, 33]
        )
      ]);
      this.fa.createElAnimations(this.$refs.innerService, [
        new Animation(FAnimation.animationStore.fadeOut, {}, [31, 32]),
        new Animation(FAnimation.animationStore.moveLeft, { x: 26 }, [31, 32])
      ]);
      this.fa.createElAnimations(this.$refs.mrs, [
        new Animation(FAnimation.animationStore.fadeIn, {}, [33, 34])
      ]);
      this.fa.createElAnimations(this.$refs.mrInner, [
        new Animation(FAnimation.animationStore.moveLeft, { x: 7 }, [33, 34])
      ]);
      this.fa.createElAnimations(this.$refs.innerAi, [
        new Animation(FAnimation.animationStore.moveRight, { x: 5 }, [33, 34])
      ]);
      this.fa.createElAnimations(this.$refs.icp, [
        new Animation(FAnimation.animationStore.fadeIn, {}, [40, 41])
      ]);

      window.addEventListener("scroll", this.execAnimationList, false);
    }, 20);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.execAnimationList);
    this.fa.destroy();
  },
  methods: {
    onResize() {
      this.$refs.viewport.style.height = window.innerHeight + "px";
    },
    execAnimationList() {
      this.fa.execAnimationList();
    }
  }
};
</script>

<style lang="scss" scoped>
.viewport {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  color: #fff;
  z-index: 100;
  .viewport-top {
    position: relative;
    height: calc(50vh - 16px);
    .logo {
      opacity: 0;
      position: absolute;
      bottom: 70px;
      left: 40px;
      width: 62px;
      height: 43px;
      background-image: url("../../../assets/images/logo.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .service {
      position: absolute;
      bottom: 0;
      left: 40px;
      display: flex;
      align-items: center;
      font-size: 45px;
      opacity: 0;
      animation: serviceShow 0.5s linear 0.3s 1 forwards;
      @keyframes serviceShow {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      .service-and-mrs {
        position: relative;
        display: inline-block;
      }
      .service-body {
        position: relative;
        display: inline-block;
        width: 194.03px;
        overflow: hidden;
        .innerService {
          display: inline-block;
        }
      }
    }
    .mrs {
      display: flex;
      justify-content: space-between;
      width: 152.02px;
      opacity: 0;
      position: absolute;
      bottom: 0;
      left: 40px;
      font-size: 45px;
      .mrs-inner {
        overflow: hidden;
        .mr-inner {
          position: relative;
          left: 7rem;
        }
        .mr-ai-inner {
          position: relative;
          right: 5rem;
        }
      }
    }
  }
  .icp {
    font-size: 12px;
    color: #fff;
    text-decoration: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    color: #b0bef2;
    opacity: 0;
  }
}
</style>
